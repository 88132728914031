import React, { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Launch from '@material-ui/icons/Launch';
import BenefitCard from '../components/BenefitCard';
import Intro from '../components/Intro';
import Strip from '../components/Strip';
import Image from '../components/Image';
import { WhiteButton } from '../components/Buttons';
import { DISCOVER_ME_URL } from '../shared/constants';

const DmIntroContainer = (): ReactElement => (
  <Strip isSection id="discover" variant="white">
    <Strip padded id="dmIntro" borderBottom variant="greyGrad">
      <Container>
        <Intro
          blurb="By partnering with healthcare providers and the wider public, we are:"
          boldTitle="Benefits"
          gutterBottom
          title="Platform"
        />

        <Grid container spacing={4}>
          <Grid item sm={12} md={4}>
            <BenefitCard
              icon={() => <Image src="/benefit1.jpg" alt="Providing benefits to participants" rounded bordered />}
              title="Providing benefits to participants"
              blurb="Working within a transparent and ethical framework, and directly with care providers, Discover Me enables everyone to view accessible summaries of their health and care data."
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <BenefitCard
              icon={() => <Image src="/gp.jpg" alt="health care organisations and providers" rounded bordered />}
              title="Enabling health care organisations and providers"
              blurb="An integrated healthcare and genetic data platform will help provide a framework to develop algorithms to improve patient care and services."
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <BenefitCard
              icon={() => <Image src="/research.jpg" alt="Science" rounded bordered />}
              title="Supporting scientific insights and discovery, to stimulate innovation"
              blurb="Creating a database of continually updated long-term patient health records along with genetic information will help provide opportunities to understand the causes and risk factors of many diseases."
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <Box textAlign="center">
              <a href={DISCOVER_ME_URL} target="_blank" rel="noreferrer" className="App-plain-link">
                <WhiteButton variant="contained" color="secondary" size="large" type="button" endIcon={<Launch />}>
                  Find out more about Discover Me
                </WhiteButton>
              </a>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Strip>
  </Strip>
);

export default DmIntroContainer;
