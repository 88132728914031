import React, { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import Image from '../Image';
import { WhiteButton } from '../Buttons';
import './Article.scss';

export interface ArticleProps {
  blurb: string;
  date: string;
  headline: string;
  pic?: string | null;
  picCorners?: boolean;
  slug?: string;
  onSelect: (slug: string) => void;
}

const Article = ({ blurb, date, headline, pic, picCorners, slug, onSelect }: ArticleProps): ReactElement => (
  <div className="App-Article">
    {pic && (
      <span
        className={cx('App-Article-pic', { 'App-Article-pic--cornered': picCorners })}
        onClick={() => {
          if (slug) {
            onSelect(slug);
          }
        }}
        /* eslint-disable @typescript-eslint/no-empty-function */
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <Image src={pic} alt={headline} rounded bordered />
      </span>
    )}
    <span className="App-Article-text">
      <Typography variant="h6">
        <Box component="span" fontWeight="600">
          {headline}
        </Box>
      </Typography>
      <Typography variant="body1" paragraph color="primary">
        {date}
      </Typography>
      <Typography variant="body2" component="span">
        <ReactMarkdown>{blurb}</ReactMarkdown>
      </Typography>
      {slug && (
        <WhiteButton
          endIcon={<ChevronRightIcon />}
          data-testid="register-dm-button"
          size="large"
          onClick={() => {
            onSelect(slug);
          }}
        >
          Find out more
        </WhiteButton>
      )}
    </span>
  </div>
);

Article.defaultProps = {
  pic: null,
  picCorners: false,
};

export default Article;
