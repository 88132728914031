import React, { ReactNode, ReactElement } from 'react';
import MuiCarousel from 'react-material-ui-carousel';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';

import './Carousel.scss';

export interface CarouselProps {
  children: ReactNode;
}

const Carousel = ({ children }: CarouselProps): ReactElement => (
  <div className="App-Carousel">
    <MuiCarousel
      animation="slide"
      autoPlay={false}
      navButtonsAlwaysVisible
      activeIndicatorIconButtonProps={{ className: 'App-Carousel-indicator--active' }}
      NavButton={({ onClick, next, prev }) => {
        return (
          <button
            type="button"
            aria-label={prev ? 'Previous Slide' : 'Next Slide'}
            tabIndex={0}
            onKeyUp={() => {
              onClick();
            }}
            onClick={() => {
              onClick();
            }}
            className="App-carousel-nav-item"
            style={{
              left: prev ? 20 : 'initial',
              right: next ? 20 : 'initial',
            }}
          >
            {next && <ChevronRightOutlinedIcon fontSize="inherit" />}
            {prev && <ChevronLeftOutlinedIcon fontSize="inherit" />}
          </button>
        );
      }}
      swipe
    >
      {children}
    </MuiCarousel>
  </div>
);

export default Carousel;
