import React, { ReactElement } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Intro from '../components/Intro';
import Strip from '../components/Strip';
import Image from '../components/Image';
import { WhiteButton } from '../components/Buttons';
import { DISCOVER_ME_URL } from '../shared/constants';

const CompanyContainer = (): ReactElement => (
  <Strip isSection id="companyWrapper">
    <Strip id="company" padded>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Intro
              blurb="Through our innovative Discover Me platform, Omnigen is integrating health and genetic data on
              100,000s of people to help discover new ways to treat diseases and manage health and care."
              boldTitle="Omnigen"
              gutterBottom
              title="About"
              align="left"
            />
            <Box textAlign="center">
              <Typography paragraph variant="body1" color="textSecondary" align="left">
                We encourage everyone to participate in Discover Me, and especially individuals from diverse communities
                to make healthcare research relevant to everyone.
              </Typography>
              <Typography paragraph variant="body1" color="textSecondary" align="left">
                Working with our trusted partners, Omnigen can help to discover novel targets for new medicines and
                diagnostic tools. We can identify people that are more likely to benefit from treatments, design better
                clinical trials and refer participants to them, including for rare diseases.
              </Typography>
            </Box>
            <WhiteButton endIcon={<ChevronRightIcon />} data-testid="team-button" size="large" href={DISCOVER_ME_URL}>
              Discover More
            </WhiteButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <Image src="/omnigenimg.jpg" alt="Omnigen" rounded bordered />
          </Grid>
        </Grid>
      </Container>
    </Strip>
  </Strip>
);

export default CompanyContainer;
