import React, { ReactElement } from 'react';
import { Link } from 'react-scroll';
import { MenuItem } from '../../shared/interfaces/menuitem.interface';
import './NavList.scss';

export interface NavListProps {
  menuItems: MenuItem[];
  onClick: (to: string) => void;
}

const NavList = ({ menuItems, onClick }: NavListProps): ReactElement => {
  return (
    <ul className="App-nav">
      {menuItems.map((item) => (
        <li key={`main-nav-${item.to}`}>
          <Link
            activeClass="active"
            className="App-nav-link"
            to={item.to}
            spy
            smooth
            duration={500}
            offset={-105}
            onClick={() => onClick(item.to)}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );
};

NavList.defaultProps = {
  onClick: () => undefined,
};

export default NavList;
