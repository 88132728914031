export const APP_CUSTOM_THEME = {
  palette: {
    primary: {
      light: '#03697F',
      main: '#103544',
      dark: '#03697F',
      contrastText: '#fff',
    },
    secondary: {
      light: '#D48700',
      main: '#D48700',
      dark: '#D48700',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['"Poppins"'].join(','),
    h1: {
      fontSize: 66,
      lineHeight: 1.1,
      letterSpacing: -2,
    },
    h2: {
      fontSize: 60,
      lineHeight: 0.95,
      letterSpacing: -2,
    },
    h3: {
      fontSize: 45,
      lineHeight: 0.95,
      letterSpacing: -2,
    },
    h4: {
      fontSize: 34,
      lineHeight: 0.95,
      letterSpacing: -2,
    },
    h5: {
      fontSize: 25,
      lineHeight: 0.95,
    },
    h6: {
      fontSize: 24,
      lineHeight: 1.25,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 20,
      lineHeight: 1.3,
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 18,
      lineHeight: 1.3,
      fontWeight: 600,
    },
  },
};

export default { APP_CUSTOM_THEME };
