import React, { ReactElement } from 'react';
import Container from '@material-ui/core/Container';

import Intro from '../components/Intro';
import Strip from '../components/Strip';
import Profile from '../components/Profile';
import Carousel from '../components/Carousel';
import ParallaxContainer from '../components/ParallaxContainer';

import { keyPeople } from '../shared/data/people';
import { PROFILE_PIC_PATH } from '../shared/constants';

const AboutContainer = (): ReactElement => (
  <Strip isSection id="aboutWrapper">
    <ParallaxContainer alt="Our Team" id="paralax2" image="/parallax/isolation@2x.jpg" />
    <Strip id="about" padded variant="greyGradRev">
      <Container>
        <Intro
          blurb="Omnigen's team has extensive academic and industry experience enabling innovation in real world data and genomics. We have diverse skills and experience spanning clinical research, technology, governance, and design."
          boldTitle="Team"
          gutterBottom
          title="Our"
        />
        <Carousel>
          {keyPeople.map((person) => (
            <Profile
              key={person.name}
              name={person.name}
              bio={person.bio}
              position={person.position}
              pic={`${PROFILE_PIC_PATH}${person.pic}`}
            />
          ))}
        </Carousel>
      </Container>
    </Strip>
  </Strip>
);

export default AboutContainer;
