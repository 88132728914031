import React, { ReactElement } from 'react';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { scroller } from 'react-scroll';
import { BsChevronDown } from 'react-icons/bs';
import { DISCOVER_ME_URL, SCROLLER_OPTIONS } from '../shared/constants';

import { ActionButton } from '../components/Buttons';
import './SplashContainer.scss';

const SplashContainer = (): ReactElement => (
  <section className="App-splash" id="home">
    <Hidden smDown>
      <div
        className="App-splash-tag"
        data-testid="splash-tag"
        onClick={() => scroller.scrollTo('overview', SCROLLER_OPTIONS)}
        onKeyDown={() => scroller.scrollTo('overview', SCROLLER_OPTIONS)}
        role="button"
        tabIndex={0}
        aria-label="Next Section"
      >
        <BsChevronDown fontSize="inherit" />
      </div>
    </Hidden>
    <Container>
      <Grid container spacing={2}>
        <Grid item md={6} lg={5}>
          <Typography variant="h1" component="h1" color="primary" gutterBottom>
            <span className="App-text--light">transforming</span>
            <br />
            <span className="App-text--bold">healthcare</span>
            <br />
            <span className="App-text--bolder">together</span>
          </Typography>
          <Typography variant="h6" component="p" paragraph style={{ maxWidth: 350 }}>
            Omnigen is building a global research community.
          </Typography>

          <div className="App-splash-buttons">
            <ActionButton
              endIcon={<ChevronRightIcon />}
              data-testid="splash-button"
              size="large"
              href={DISCOVER_ME_URL}
            >
              Find out more about Discover Me
            </ActionButton>
          </div>
        </Grid>
      </Grid>
    </Container>
  </section>
);

export default SplashContainer;
