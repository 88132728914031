import { Person } from '../interfaces/people.interface';

export const keyPeople: Person[] = [
  {
    pic: 'ms@1.jpg',
    name: 'Manjinder Sandhu',
    position: 'CEO & Founder',
    bio: `Manjinder is a Professor of Population Health and Data Sciences at Imperial College London. He trained at King's College London, the London School of Hygiene and Tropical Medicine, and the University of Cambridge. He was also senior group leader at the Wellcome Sanger Institute between 2009 and 2019.\n\nHis academic research focuses on creating and interpreting health and genetic data and integrating technological advances at the population health level. As Omnigen's Founder, his work now centres on integrating real-world data, including electronic health records, and genomic resources in Europe, the Middle East, Asia and Africa.`,
  },
  {
    pic: 'ty.jpg',
    name: 'Tom York',
    position: 'Head of Research',
    bio: `Tom is a Medical Doctor who qualified at the University of Glasgow before continuing his training at the University of St Andrews, the University of Exeter, and Imperial College London. He has specialised in Pharmaceutical Medicine where he has gained substantial experience in clinical trial design and gene therapeutics. Much of Tom's work has focused on novel methodology to engage rare and ultra-rare disease populations in clinical research.\n\nAs a Fellow of the British Computer Society, he has a particular interest in Clinical Informatics and the application of large datasets to challenges in healthcare. At Omingen, he is responsible for the strategic leadership of the Research function, delivering the next stage of our expansion into new global regions.`,
  },
  {
    pic: 'lb@1.jpg',
    name: 'Louise Barr',
    position: 'Strategic Partnerships Lead',
    bio: `Louise has more than ten years' experience in commercial and academic research. She completed her PhD in Epidemiology at the University of Cambridge and a Masters in Public Health from London School of Hygiene and Tropical Medicine. Her research has primarily focussed on large-scale population health research in the African region. As part of this work, she has also been involved in capacity-building initiatives such as the African Partnership for Chronic Disease Research.\n\nAt Omnigen, she is responsible for managing strategic partnerships with clients and vendors to ensure the delivery of the Discover Me programme.`,
  },
  {
    pic: 'mc.jpg',
    name: 'Mark Cullen',
    position: 'Head of Product & Technology',
    bio: `Mark has over 20 years of experience in user-centred design and development in various industries, from finance, logistics and e-commerce. Mark prides himself on harmonizing cutting-edge technology with human-centric design principles, resulting in innovative and intuitive digital products.\n\nAt Omnigen, Mark is responsible for defining the product strategy, setting the product vision, and ensuring the successful execution of product initiatives, including customer insights where possible to drive successful outcomes.`,
  },
  {
    pic: 'hw.jpg',
    name: 'Helen Weedon',
    position: 'Quality, Learning, and Development Lead',
    bio: `Helen has worked in the clinical study space for over 10 years, within both academic institutions and pharmaceutical companies. She has 6 year's experience in first-line quality assurance, having previously worked supporting the continuous improvement of quality management systems (QMS) for early-phase clinical trials. Experience includes leading cross-functional process improvement initiatives, quality issue/CAPA and risk management, audit/inspection readiness and vendor quality oversight.\n\nAt Omnigen, Helen is responsible for driving the strategy for continuous improvement of the Company's QMS, with a current focus on process improvement, along with overseeing compliance to the existing quality framework and promoting a quality mindset throughout the organisation.`,
  },
  {
    pic: 'js@1.jpg',
    name: 'Joelle Sandhu',
    position: 'Quality and Study Operations Consultant',
    bio: `Joelle has a degree in Pharmacology from Kings College London and a Masters in Clinical Research from Cardiff University. With a background of over 20 years in industry, Joelle has led international, cross functional teams across all phases of clinical trials and has comprehensive experience of planning, executing and reporting global studies. Joelle has expertise working to and developing industry standard Quality Management Systems.\n\nWorking in collaboration with consultants with extensive industry experience, Joelle is responsible for quality assurance and study operations on behalf of Omnigen.\n\nJoelle is a consultant employed by Trey Consulting Ltd where she is the Managing Director.`,
  },
];

export default { keyPeople };
