import i18next from 'i18next';
import dmsa_en from '../../translations/en/dmsa.json';
import dmsa_zu from '../../translations/zu/dmsa.json';
import footer_en from '../../translations/en/footer.json';
import footer_zu from '../../translations/zu/footer.json';
import dmsaPrivacy_en from '../../translations/en/dmsa-privacy.json';
import dmsaPrivacy_zu from '../../translations/zu/dmsa-privacy.json';
import { Language } from '../interfaces/language.type';

type I18nextType = typeof i18next;

export const setupI18next = (defaultLanguage = Language.EN): I18nextType => {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: defaultLanguage,
    resources: {
      en: {
        dmsa: dmsa_en,
        footer: footer_en,
        dmsaPrivacy: dmsaPrivacy_en,
      },
      zu: {
        dmsa: dmsa_zu,
        footer: footer_zu,
        dmsaPrivacy: dmsaPrivacy_zu,
      },
    },
  });
  return i18next;
};

export default setupI18next;
