import React, { ReactElement, ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';
import ScrollAnimation from 'react-animate-on-scroll';
import './BenefitCard.scss';

export interface BenefitCardProps {
  title: string;
  icon?: () => ReactNode;
  blurb: string;
}

const BenefitCard = ({ title, icon, blurb }: BenefitCardProps): ReactElement => (
  <div className="App-BenefitCard">
    {icon && (
      <span className="App-BenefitCard-icon">
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          {icon()}
        </ScrollAnimation>
      </span>
    )}
    <span>
      <Typography color="inherit" gutterBottom variant="subtitle2" component="h3">
        {title}
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary">
        {blurb}
      </Typography>
    </span>
  </div>
);

BenefitCard.defaultProps = {
  icon: () => undefined,
};

export default BenefitCard;
