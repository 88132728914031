import React, { ReactElement } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { MdOutlineHandshake } from 'react-icons/md';
import { RiScalesLine } from 'react-icons/ri';
import { BiCheckShield } from 'react-icons/bi';

import KeyValue from '../components/KeyValue';
import Strip from '../components/Strip';
import Intro from '../components/Intro';
import './OverviewContainer.scss';

const OverviewContainer = (): ReactElement => (
  <div className="App-overview">
    <Strip isSection id="overview" padded variant="pattern">
      <Container>
        <Intro boldTitle="Values" gutterBottom title="Our" color="inherit" />
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <KeyValue
              icon={() => <MdOutlineHandshake size={80} />}
              title="Trust"
              blurb="We have created a robust privacy framework to ensure participant trust in our data management and sharing practices. We are compliant with GDPR and related laws."
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <KeyValue
              icon={() => <RiScalesLine size={80} />}
              title="Ethics"
              blurb="All research is independently assessed and approved by government-recognised research ethics committees or institutional review boards to ensure our work is of the highest ethical standards."
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <KeyValue
              icon={() => <BiCheckShield size={80} />}
              title="Security"
              blurb="Designing a secure, reliable data environment is a central part of our technology and we combine these robust privacy technologies with strong governance."
            />
          </Grid>
        </Grid>
      </Container>
    </Strip>
  </div>
);

export default OverviewContainer;
