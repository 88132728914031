import React, { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Intro from '../components/Intro';
import Strip from '../components/Strip';

const PartnersContainer = (): ReactElement => (
  <Strip isSection padded id="partners">
    <Container>
      <Intro boldTitle="Collaborators" gutterBottom title="Our" />
      <Grid container spacing={3}>
        <Hidden smDown>
          <Grid item xs={3} />
        </Hidden>
        <Grid item xs={6} md={3}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src="/logos/logo-knp.png" alt="KwaZulu Natal Province" style={{ maxWidth: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src="/logos/logo-ukzn.png" alt="University of KwaZulu Natal" style={{ maxWidth: '100%' }} />
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid item xs={3} />
        </Hidden>
      </Grid>
      <hr style={{ marginTop: 40, marginBottom: 40, opacity: 0.3 }} />
      <Intro boldTitle="Partners" gutterBottom title="Our" />
      <Grid container spacing={3}>
        <Grid item xs={6} md={4}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src="/logos/logo-rgc.png" alt="RGC" style={{ maxWidth: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src="/logos/logo-gsk.png" alt="gsk" style={{ maxWidth: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src="/logos/logo-az.png" alt="AstraZeneca" style={{ maxWidth: '100%' }} />
          </Box>
        </Grid>

        <Grid item xs={6} md={4}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src="/logos/logo-roche.png" alt="Roche" style={{ maxWidth: '100%' }} />
          </Box>
        </Grid>

        <Grid item xs={6} md={4}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src="/logos/logo-genetech.png" alt="Genetech" style={{ maxWidth: '100%' }} />
          </Box>
        </Grid>

        <Grid item xs={6} md={4}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src="/logos/logo-pfizer.png" alt="Pfizer" style={{ maxWidth: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src="/logos/logo-alnylam.png" alt="Alnylam" style={{ maxWidth: '100%' }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  </Strip>
);

export default PartnersContainer;
