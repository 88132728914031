import React, { Fragment, ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Emphasis from '../Emphasis';
import { COMPANY_ADDRESS } from '../../shared/constants';
import logo from '../../assets/logo/OmnigenWhiteOnly.svg';
import './Footer.scss';

export interface FooterProps {
  address: string;
  companyName: string;
  telephone: string;
  onPrivacyOpen?: () => void;
}

const Footer = ({ address, companyName, onPrivacyOpen, telephone }: FooterProps): ReactElement => (
  <footer className="App-Footer" id="contact">
    <div className="App-Footer-main">
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <Box textAlign={{ xs: 'center', md: 'left' }}>
              <Box mb={2}>
                <img src={logo} alt={companyName} width={150} />
              </Box>
              <Typography variant="h5" component="h2" color="inherit">
                <Emphasis text="Contact Us" />
              </Typography>

              <Box marginTop={{ xs: 2 }}>
                <Typography variant="body2" color="inherit">
                  Email
                </Typography>
                <Typography variant="h6" component="h3" color="inherit" paragraph>
                  <span style={{ fontWeight: 800 }}>info</span>
                  <span style={{ fontWeight: 200 }}>@</span>
                  <span style={{ fontWeight: 600 }}>omnigenbiodata</span>
                  <span style={{ fontWeight: 200 }}>.co.uk</span>
                </Typography>
                {telephone && (
                  <>
                    <Typography variant="body2" color="inherit">
                      Telephone
                    </Typography>
                    <Typography variant="h5" component="h3" color="inherit" paragraph>
                      {telephone}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box textAlign={{ xs: 'center', md: 'left' }} marginTop={{ xs: 2, md: 0 }}>
              <Typography variant="h5" component="h2" color="inherit">
                <Emphasis text="Company Details" />
              </Typography>
              {address && companyName && (
                <Box marginTop={{ xs: 2 }}>
                  <Typography variant="body2" color="inherit">
                    <strong>{companyName}</strong>
                    {address.split(',').map((line) => (
                      <Fragment key={`address-line-${line}`}>
                        ,
                        <br />
                        {line}
                      </Fragment>
                    ))}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
    <div className="App-Footer-extra">
      <Container>
        <Typography variant="body2">
          Copyright {companyName} {new Date().getFullYear()}. All rights reserved.{' '}
          <Hidden mdUp>
            <br />
          </Hidden>
          <span
            data-testid="privacy-open"
            role="button"
            tabIndex={0}
            onKeyUp={onPrivacyOpen}
            onClick={onPrivacyOpen}
            className="App-footer-link"
          >
            Privacy Notice
          </span>
        </Typography>
        <Typography variant="body2">Registered address {COMPANY_ADDRESS}</Typography>
      </Container>
    </div>
  </footer>
);

export default Footer;
