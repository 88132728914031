import React, { ReactElement } from 'react';
import './Emphasis.scss';

interface EmphasisProps {
  text: string;
  highlightIndex?: number;
}

const Emphasis = ({ text, highlightIndex }: EmphasisProps): ReactElement => {
  const words = text.trim().split(' ');
  return (
    <span>
      {words.map((word, index) => {
        const spacing = index === 0 ? '' : ' ';
        if (index === highlightIndex) {
          return (
            <strong key={`${index.toString()}-emph`}>
              {spacing}
              {word}
            </strong>
          );
        }
        return `${spacing}${word}`;
      })}
    </span>
  );
};

Emphasis.defaultProps = {
  highlightIndex: 0,
};

export default Emphasis;
