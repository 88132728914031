import React, { ReactElement, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { DialogContent } from '@material-ui/core';
import { AiOutlineClose } from 'react-icons/ai';
import ReactMarkdown from 'react-markdown';
import Intro from '../components/Intro';
import Strip from '../components/Strip';
import Image from '../components/Image';
import ArticleComponent from '../components/Article';
import { Article } from '../shared/interfaces/articles.interface';

import { NEWS_PIC_PATH } from '../shared/constants';
import { articles } from '../shared/data/articles';
import { CloseButton } from '../components/Buttons';

const NewsContainer = (): ReactElement => {
  const [currentArticle, setCurrentArticle] = useState<undefined | Article>();

  const handleSelect = (slug: string) => {
    setCurrentArticle(articles.filter((row: Article) => row.slug === slug).pop());
  };

  const handleClose = () => {
    setCurrentArticle(undefined);
  };

  return (
    <>
      <Strip isSection padded id="news">
        <Container>
          <Intro
            blurb="The latest news and events from the team and our research initiatives at Omnigen."
            boldTitle="News"
            gutterBottom
            title="Omnigen"
          />
          <Grid container spacing={4}>
            {articles &&
              articles
                .filter((article: Article) => article.active)
                .map((article: Article) => (
                  <Grid item sm={12} md={12} key={`main-article-${article.headline}`}>
                    <ArticleComponent
                      pic={`${NEWS_PIC_PATH}${article.pic}`}
                      date={article.date}
                      headline={article.headline}
                      blurb={article.blurb}
                      slug={article.slug}
                      onSelect={handleSelect}
                    />
                  </Grid>
                ))}
          </Grid>
        </Container>
      </Strip>
      {currentArticle && (
        <Dialog
          onClose={() => {
            handleClose();
          }}
          open
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <Box textAlign="right">
              <CloseButton
                data-testid="close-article-button"
                size="small"
                onClick={() => {
                  handleClose();
                }}
              >
                <AiOutlineClose size={20} />
              </CloseButton>
            </Box>
            <Typography variant="h1" component="h2" color="primary" paragraph>
              <strong>{currentArticle.headline}</strong>
            </Typography>{' '}
            <Typography variant="body1" color="primary">
              <strong>{currentArticle.date}</strong>
            </Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              by Omnigen Biodata Ltd
            </Typography>
            <Image
              src={`${NEWS_PIC_PATH}${currentArticle.picLarge}`}
              alt={currentArticle.headline}
              rounded
              bordered
              style={{ maxWidth: '100%' }}
            />
            <Typography variant="body1" component="span">
              <ReactMarkdown>{currentArticle.article || ''}</ReactMarkdown>
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default NewsContainer;
