import React, { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Intro from '../components/Intro';
import Strip from '../components/Strip';
import { LinkInButton } from '../components/Buttons';

import { EMAIL_CAREERS, LINKEDIN_JOBS_URL } from '../shared/constants';

const CareersContainer = (): ReactElement => (
  <Strip isSection minHeight={400} padded id="careers">
    <Container>
      <Intro
        blurb="We are a small health research and development start-up company based in Cambridge, UK. We are growing fast. If you share our values and approach to building biodata ethically and working with healthcare providers and the public, and have skills and passion to contribute, contact us at"
        boldTitle="Careers"
        gutterBottom
        title="Omnigen"
      />
      <Box textAlign={{ xs: 'center' }}>
        <Box mb={7}>
          <a href={`mailto:${EMAIL_CAREERS}`} className="App-plain-link">
            <Hidden mdUp>
              <Typography variant="h6" component="h3" color="primary">
                <span className="App-text--bolder">people</span>
                <span className="App-text--light">@</span>
                <span className="App-text--bold">omnigenbiodata</span>
                <span className="App-text--light">.co.uk</span>
              </Typography>
            </Hidden>
            <Hidden smDown>
              <Typography variant="h4" component="h3" color="primary">
                <span className="App-text--bolder">people</span>
                <span className="App-text--light">@</span>
                <span className="App-text--bold">omnigenbiodata</span>
                <span className="App-text--light">.co.uk</span>
              </Typography>
            </Hidden>
          </a>
        </Box>
        <a href={LINKEDIN_JOBS_URL} target="_blank" rel="noreferrer" className="App-plain-link">
          <LinkInButton size="large" startIcon={<LinkedInIcon />} endIcon={<ChevronRightIcon />}>
            Visit us on LinkedIn
          </LinkInButton>
        </a>
      </Box>
    </Container>
  </Strip>
);

export default CareersContainer;
