import React, { ReactElement } from 'react';

import MainLayout from '../../layout/Main';
import AboutContainer from '../../containers/AboutContainer';
import SplashContainer from '../../containers/SplashContainer';
import NewsContainer from '../../containers/NewsContainer';
import OverviewContainer from '../../containers/OverviewContainer';
import DmContainer from '../../containers/DmContainer';
import CareersContainer from '../../containers/CareersContainer';
import CompanyContainer from '../../containers/CompanyContainer';
import PartnersContainer from '../../containers/PartnersContainer';
import Dm2Container from '../../containers/Dm2Container';

function HomeScene(): ReactElement {
  return (
    <MainLayout>
      <SplashContainer />
      <CompanyContainer />
      <OverviewContainer />
      <DmContainer />
      <NewsContainer />
      <PartnersContainer />
      <Dm2Container />
      <AboutContainer />
      <CareersContainer />
    </MainLayout>
  );
}

export default HomeScene;
