export const COMPANY_NAME = 'Omnigen Biodata Ltd';
export const COMPANY_TELEPHONE = '01223 652745';
export const COMPANY_ADDRESS = 'Four Station Square, Cambridge, CB1 2GE';
export const CONTACT_ADDRESS = '11 Hope Street Yard, Hope Street, Cambridge, CB1 3NA';

export const EMAIL_DPO = 'dpo@omnigenbiodata.co.uk';
export const EMAIL_CAREERS = 'people@omnigenbiodata.co.uk';
export const EMAIL_DMSA_STUDY = 'discovermesa@ukzn.ac.za';
export const TEL_STUDY = '+27 (0) 83 4633 156';

export const LINKEDIN_PROFILE_URL = 'https://www.linkedin.com/company/omnigen-biodata-ltd';
export const LINKEDIN_JOBS_URL = 'https://uk.linkedin.com/company/omnigen-biodata-ltd';
export const DISCOVER_ME_URL = 'https://www.discovermestudy.com';
export const DISCOVER_ME_UK_URL = 'https://www.discovermestudy.com/dmuk';
export const PARTICIPANT_PORTAL_URL = 'https://dmsa-portal.omnigenbiodata.co.uk/';
export const DISCOVER_ME_SA_URL = 'https://www.discovermestudy.com/dmsa';
export const DISCOVER_ME_SA_FRIENDLY_URL = 'www.omnigenbiodata.co.uk/dmsa';
export const DISCOVER_ME_SA_FULL_URL = 'https://www.omnigenbiodata.co.uk/dmsa';
export const SUPERVISORY_AUTHORITY_URL =
  'https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm';
export const PROFILE_PIC_PATH = '/people/';
export const NEWS_PIC_PATH = '/news/';

export const SCROLLER_OPTIONS = {
  duration: 800,
  delay: 0,
  smooth: 'easeInOutQuart',
  offset: -105,
};

export const ROUTES = {
  dmsa: '/dmsa',
  root: '/',
};

export default {
  DISCOVER_ME_UK_URL,
  EMAIL_DPO,
  LINKEDIN_PROFILE_URL,
  PROFILE_PIC_PATH,
  SCROLLER_OPTIONS,
  ROUTES,
};
