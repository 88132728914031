import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';
import './Intro.scss';

interface IntroProps {
  title: string;
  blurb?: string | null;
  boldTitle?: string | null;
  gutterBottom?: boolean;
  // eslint-disable-next-line react/require-default-props
  color?: 'inherit' | 'initial' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  // eslint-disable-next-line react/require-default-props
  align?: 'center' | 'left' | 'right';
}

const Intro = ({
  blurb,
  boldTitle,
  gutterBottom,
  title,
  color = 'primary',
  align = 'center',
}: IntroProps): ReactElement => (
  <div
    className={cx('App-heading-block', {
      'App-heading-block--gutterBottom': gutterBottom,
    })}
    style={{ width: '100%' }}
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" component="h2" className="App-text--light" gutterBottom color={color} align={align}>
          {title} {boldTitle && <strong className="App-text--bolder">{boldTitle}</strong>}
        </Typography>
        {blurb && (
          <Typography variant="body1" align={align}>
            {blurb}
          </Typography>
        )}
      </Grid>
    </Grid>
  </div>
);

Intro.defaultProps = {
  blurb: null,
  boldTitle: null,
  gutterBottom: false,
};

export default Intro;
