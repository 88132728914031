import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { DISCOVER_ME_SA_URL, ROUTES } from '../shared/constants';
import HomeScene from './HomeScene';

function Scenes(): ReactElement {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.dmsa}
        component={() => {
          window.location.href = DISCOVER_ME_SA_URL;
          return null;
        }}
      />
      <Route path={ROUTES.root} component={HomeScene} />
    </Switch>
  );
}

export default Scenes;
