import React, { ReactElement, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import Scenes from './scenes';

import './App.scss';

const instance = createInstance({
  urlBase: 'https://discovermestudy.matomo.cloud',
  siteId: 4,
});

function App(): ReactElement {
  const [analyticsOn, toggleAnalytics] = useState(window.Osano ? window.Osano.cm.analytics : false);
  useEffect(() => {
    if (!window.Osano) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.Osano.cm.addEventListener('osano-cm-consent-saved', (consent: any) => {
      if (consent.ANALYTICS === 'ACCEPT') {
        toggleAnalytics(true);
      } else if (consent.ANALYTICS === 'DENY') {
        toggleAnalytics(false);
      }
    });
    // eslint-disable-next-line consistent-return
    return window.Osano.cm.removeEventListener('osano-cm-consent-saved', null);
  }, []);
  return analyticsOn ? (
    <MatomoProvider value={instance}>
      <Router>
        <Scenes />
      </Router>
    </MatomoProvider>
  ) : (
    <Router>
      <Scenes />
    </Router>
  );
}

export default App;
